import React from 'react'

type Props = {
	items: string[]
}

function Steps({ items }: Props) {
	return (
		<ul className="steps steps-vertical lg:steps-horizontal grow-0 lg:w-full">
			{items.map((item, index) => (
				<li key={index} className="step step-primary">
					<p className="text-left lg:text-center">{item}</p>
				</li>
			))}
		</ul>
	)
}

export default Steps

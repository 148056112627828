import { type RenderableTreeNode } from '@markdoc/markdoc'
import Cta from '~/components/cms/cta'
import { Markdown } from '~/components/cms/markdown'
import { type HeroInputProps } from '~/components/cms/utils'
import { ImagePosition } from '~/data/api'
import { cn } from '~/utils/cn'

export type HeroProps = HeroInputProps & {
	markdownText: RenderableTreeNode
	markdownGrid: RenderableTreeNode[]
}

export default function Hero({
	headline,
	headlineShort,
	imagePosition,
	image,
	cta,
	markdownText,
	markdownGrid,
}: HeroProps) {
	const hasImage = !!image
	const hasImageOverlay = hasImage && imagePosition === ImagePosition.Background
	const hasImageFigure =
		hasImage &&
		(imagePosition === ImagePosition.Right ||
			imagePosition === ImagePosition.Left)

	return (
		<div
			className="hero bg-neutral bg-neutral/70 text-neutral-content relative -mx-8 w-screen bg-linear-to-b from-neutral-300 to-stone-400 md:mx-0 md:w-auto md:rounded-lg"
			style={
				hasImageOverlay ? { backgroundImage: `url(${image.image1200})` } : {}
			}
		>
			{hasImageOverlay && (
				<div className="hero-overlay bg-neutral/70 md:rounded-lg" />
			)}

			<div
				className={cn(
					'hero-content my-4 space-y-4 text-center md:my-16 md:space-y-16',
					hasImageFigure && 'flex-col md:flex-row',
					hasImageFigure &&
						imagePosition === ImagePosition.Right &&
						'lg:flex-row-reverse',
				)}
			>
				{hasImageFigure && (
					<picture>
						<source media="(max-width: 660px)" srcSet={image?.image600} />
						<source
							media="(min-width: 661px) and (max-width: 768px)"
							srcSet={image?.image1200}
						/>
						<source media="(min-width: 769px)" srcSet={image?.image600} />
						<img
							src={image?.image600}
							alt=""
							className="rounded-lg shadow-2xl md:max-w-sm"
						/>
					</picture>
				)}
				<div
					className={cn('flex flex-col items-center space-y-6 md:space-y-12')}
				>
					<div>
						<div className="hidden md:block">
							<h1
								className={cn(
									'text-5xl leading-tight font-semibold text-white',
									hasImageOverlay && 'text-white',
								)}
							>
								{headline}
							</h1>
						</div>
						<div className="md:hidden">
							<h1
								className={cn(
									'text-3xl leading-tight font-semibold text-white',
									hasImageOverlay && 'text-white',
								)}
							>
								{headlineShort || headline}
							</h1>
						</div>
					</div>
					{markdownText && (
						<Markdown
							content={markdownText}
							className={cn(
								hasImageOverlay &&
									'prose-gray! prose-invert! prose-ul:mb-0 text-white! marker:text-white',
								!hasImageFigure && 'max-w-4xl',
							)}
						/>
					)}
					{markdownGrid.length > 0 && (
						<div
							className={cn(
								'md:grid md:grid-cols-2 md:gap-0',
								!hasImageFigure && 'max-w-4xl',
							)}
						>
							{markdownGrid.map((node, index) => (
								<Markdown
									key={index}
									content={node}
									className={cn(
										'text-left text-xl',
										hasImageOverlay &&
											'prose-gray! prose-invert! prose-ul:m-0 text-white! marker:text-white',
									)}
								/>
							))}
						</div>
					)}
					{cta && <Cta {...cta} />}
				</div>
			</div>
		</div>
	)
}

import { cva } from 'class-variance-authority'
import { Link } from 'react-router'
import { type CtaFragment } from '~/data/api'

type CtaProps = Pick<CtaFragment, 'text' | 'url' | 'style' | 'size' | 'outline'>

const variants = cva('btn max-w-full rounded-lg', {
	variants: {
		style: {
			accent: 'btn-accent',
			error: 'btn-error',
			ghost: 'btn-ghost',
			info: 'btn-info',
			link: 'btn-link',
			neutral: 'btn-neutral',
			primary: 'btn-primary',
			secondary: 'btn-secondary',
			success: 'btn-success',
			warning: 'btn-warning',
		},
		size: {
			tiny: 'btn-xs',
			small: 'btn-sm',
			large: 'btn-lg px-6 py-8',
			normal: '',
		},
		outline: {
			true: 'btn-outline',
		},
	},
	defaultVariants: {
		style: 'neutral',
		size: 'normal',
	},
})

export default function Cta({ text, url, style, size, outline }: CtaProps) {
	return (
		<Link className={variants({ style, size, outline })} to={url}>
			{text}
		</Link>
	)
}

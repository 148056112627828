import { type ContentBlockProps } from '~/components/cms/content-block'
import { type HeroProps } from '~/components/cms/hero'
import { type ContentBlockFragment, type HeroFragment } from '~/data/api'
import { type Results } from '~/domain/calc/products.server'

export type HeroInputProps = Pick<
	HeroFragment,
	| 'headline'
	| 'headlineShort'
	| 'imagePosition'
	| 'text'
	| 'grid'
	| 'image'
	| 'cta'
>

export function prepareHeroProps(data: HeroInputProps): HeroProps {
	return {
		...(data as HeroProps),
	}
}

export type ContentBlockInputProps = Pick<
	ContentBlockFragment,
	| 'content'
	| 'imagePosition'
	| 'image'
	| 'cta'
	| 'productsList'
	| 'steps'
	| 'heading'
>

export function prepareContentBlockProps(
	data: ContentBlockInputProps,
	sampleResults: Results,
): ContentBlockProps {
	return {
		...(data as ContentBlockProps),
		sampleResults,
	}
}

import ContentBlock from '~/components/cms/content-block'
import Faq from '~/components/cms/faq'
import Hero from '~/components/cms/hero'
import {
	prepareContentBlockProps,
	prepareHeroProps,
} from '~/components/cms/utils'
import { type PageContentFragment } from '~/data/api'
import { type Results } from '~/domain/calc/products.server'

type PageProps = {
	pageContent: PageContentFragment[]
	sampleResults: Results
}

export default function Page({ pageContent, sampleResults }: PageProps) {
	return (
		<>
			{pageContent.map((content, index) => {
				switch (content.__typename) {
					case 'Hero':
						return <Hero key={index} {...prepareHeroProps(content)} />
					case 'ContentBlock':
						return (
							<ContentBlock
								key={index}
								{...prepareContentBlockProps(content, sampleResults)}
							/>
						)
					case 'Faq':
						return (
							<Faq key={index} items={content.items} title={content.title} />
						)
					default:
						return null
				}
			})}
		</>
	)
}
